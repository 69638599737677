const dev = {
  API_ENDPOINT_URL: 'https://arapaho-api.everestapp.mn',
	RECAPTCHA_SITE_KEY: '6LcJy5kcAAAAAO_k2dMrIbjTPc8WCp6eBdlI9sm-',
	QPAY_FEE_AMT: 0
};

const prod = {
  API_ENDPOINT_URL: 'https://arapaho-api.everestapp.mn/',
	RECAPTCHA_SITE_KEY: '6LcJy5kcAAAAAO_k2dMrIbjTPc8WCp6eBdlI9sm-',
	QPAY_FEE_AMT: 3000
};

const test = {
  API_ENDPOINT_URL: 'https://arapaho-api.everestapp.mn/',
	RECAPTCHA_SITE_KEY: '6LcJy5kcAAAAAO_k2dMrIbjTPc8WCp6eBdlI9sm-',
	QPAY_FEE_AMT: 0
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()